import axios from "axios";

let authApi = axios.create({
  baseURL: "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },
});

let authApi1 = axios.create({
  baseURL: "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "x-api-key": "ffWim00xLx52kC5AI8xJk5B1tSBrRnyH3TqOv8Y5",
    "Content-Type": "application/json",
  },
});

let authApi2 = axios.create({
  baseURL: "https://ytvpnvug1k.execute-api.ap-south-1.amazonaws.com/prod/",
  headers: {
    "Content-Type": "application/json",
  },
});

let authApi3 = axios.create({
  baseURL: "https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com/prod/",
  headers: {
    "x-api-key": "ffWim00xLx52kC5AI8xJk5B1tSBrRnyH3TqOv8Y5",
    "Content-Type": "application/json",
  },
});

//https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com/prod/mobiezyb2c_v3_managecomplaint_prc

let authApi4 = axios.create({
  baseURL: "https://h45om730i6.execute-api.ap-south-1.amazonaws.com/prod/",
  headers: {
    "Content-Type": "application/json",
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  cashfree_init(value) {
    return authApi.post(
      "https://c2.mobiezy.in/api/cashfree/generate_token_link_renewV2.php",
      value
    );
  },
  cashfree_init_payment(value) {
    return authApi.post(
      "https://rzehziqrmb.execute-api.us-west-2.amazonaws.com/prod/generate_token_link_payment",
      value
    );
  },
  
  fetchCustnum(value) {
    return authApi.post(
      "https://rzehziqrmb.execute-api.us-west-2.amazonaws.com/prod/axomQrVerify",
      value
    );
  },
  cashfree_init_upi_link(value) {
    return authApi.post(
      "https://rzehziqrmb.execute-api.us-west-2.amazonaws.com/prod/cashfree_qr_link_generation",
      value
    );
  },
  cashfree_add(value) {
    return authApi.post(
      "https://c2.mobiezy.in/api/cashfree/generate_token_new_modify.php",
      value
    );
  },
  getdetail(value) {
    return authApi1.post(
      "https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com/prod/mobipay_directpayemnt_getcustinfodetails_prc",
      value
    );
  },
  getdetailCust(value) {
    return authApi3.post(
      "https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com/prod/mobipay_flagBased_payment",
      value
    );
  },
  getdetailCustByVc(value) {
    return authApi3.post(
      "https://rzehziqrmb.execute-api.us-west-2.amazonaws.com/prod/mobipay_flagBased_payment_ByVc",
      value
    );
  },
  getCustInfo(value) {
    return authApi1.post(
      "/prod/QR_Payemnt_CustInfo",
      value
    );
  },
  sync(value) {
    return authApi.post("https://c2.mobiezy.in/api/b2c/pack_syncV2.php", value);
  },
  active(value) {
    return authApi.post(
      "https://c2.mobiezy.in/api/react/active_stb_id.php",
      value
    );
  },
  fetchAds(value) {
    return authApi2.post("fetch_ads", value);
  },
  fetchChannels(value) {
    return authApi3.post("mobiezyb2c_v3_addchannelpacksv2", value);
  },
  stbCount(value) {
    return authApi4.post("STB_COUNT", value);
  },
  addComplaints(value) {
    return authApi3.post("mobiezyb2c_v3_managecomplaint_prc", value);
  },
  fetchComplaints(value) {
    return authApi3.post("mobiezyb2c_v3_getcustomercomplaints", value);
  },
  fetchTransactions(value){
    return authApi3.post("mobiezy_v3_getcustomerpaymenthistory", value);
  }
};
