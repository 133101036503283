import React, { useState, useEffect } from "react";
import AuthApi1 from "../services/authApi";
import kanton from "../../assets/images/kanton.png";
import logo1 from "../../assets/images/2.png";
import { useHistory } from "react-router-dom";

import Loader from "react-js-loader";

const Login = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
 
  if(localStorage.getItem("custNumbervalue")){
    history.push(`/Home?custNumber=${localStorage.getItem("custNumbervalue")}`);
	}
  // Handle phone number input
  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  // Save data to localStorage
  const signIn = async () => {
    if (phoneNumber.trim() === "") {
      alert("Please enter a name before saving.");
      return;
    } 
    setLoading(true); 
   
    let userData = {
      VC_NUMBER: phoneNumber,
    };
    await AuthApi1.fetchCustnum(userData)
      .then((res) => {
        console.log(res);
        setLoading(false); 
        if (res.status === 200) {
          var custnumLength = res.data.customer_number.length;
          console.log(res.data);
          console.log(res.data.customer_number);
          console.log(res.data.customer_number.length);

          if (custnumLength === 1) {
           localStorage.setItem("custNumbervalue",res.data.customer_number[0].codeme);
     
          history.push(`/Home?custNumber=${localStorage.getItem("custNumbervalue")}`);
		 console.log( localStorage.getItem("custNumbervalue"));
	       } else if(custnumLength > 1) {
              alert("VC Number Matched with multiple customer. Contact your Cable Operator.");
          }
          else{
              alert("This service is not available with your local cable operator");
          }
      }
      })
      .catch((e) => {
        setLoading(false);
        alert("Internet issue");
        console.log(e);
      });
  };



    return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        margin: 0,
        padding: 0,
        backgroundColor: "#151719",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
		
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "400px",
          backgroundColor: "white",
          padding: "30px",
          borderRadius: "15px",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
          margin: "20px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <form>
            <center>
              <img src={kanton} alt="logo" width="200" height="200" />
            </center>
            <center style={{"display":"none"}}>
              <span
                style={{
                  fontSize: "26px",
                  fontWeight: "bold",
                  color: "#ff8c00",
                }}
              >
                Mobi<span style={{ color: "#ff8c00" }}>Online</span> Payment
              </span>
            </center>
            <hr
              style={{
                width: "100%",
                marginTop: "20px",
                marginBottom: "20px",
                border: "0",
                borderTop: "1px solid #ccc",
              }}
            />
            <div className="form-group" style={{"display":"block"}}>
              <label
                style={{
                  fontSize: "14px",
                  marginBottom: "8px",
                  textAlign: "left",
                }}
              >
                Enter Phone Number :
              </label>
              <input
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "16px",
                  borderRadius: "15px",
                  border: "1px solid #ccc",
                  marginBottom: "10px",
                  marginTop: "5px",
                  outline: "none",
                  boxSizing: "border-box",
                }}
                type="tel"
                id="phoneNumber"
                onChange={handlePhoneNumber}
                pattern="[1-9]{1}[0-9]{9}"
                maxLength="10"
                placeholder="Phone Number"
                required
              />
              
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {errors.phoneNumber}
              </div>
            </div>
            <center>
            {loading ? (
                  // Show loader when loading
                  <center style={{ width: "100%" }}>
                  <div className="hero-content">
                    <div style={{ marginTop: "5px" }}>
                      <Loader
                        type="box-rectangular"
                        bgColor={"#FFA500"}
                        color={"#FFA500"}
                        size={60}
                      />
                    </div>
                  </div>
                </center>
                ) : (
                    <button
                style={{
                  width: "100%",
                  padding: "14px 30px",
                  fontSize: "18px",
                  borderRadius: "20px",
                  backgroundColor: "#ff8c00",
                  border: "none",
                  color: "white",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  marginTop: "20px",
                }}
                type="button"
                onClick={() => signIn()}
              >
                LOGIN
              </button>
                )}
          
            </center>
            <center style={{"display":"none"}}>
              <p style={{"display":"inline-flex"}}>
                Made with{" "}
                <img src={logo1} alt="logo" width="20" height="20" /> in India!
              </p>
            </center>
			<center>
			 
                <label
                  style={{
                    color: "black",
                    fontSize: "11px",
					fontWeight:"bold"
                  }}
                >
                  Proudly powered by mobiezy 
				    
                </label> 
              </center>
			  <center>
			 
                <label
                  style={{
                    color: "green",
                    fontSize: "11px",
                  }}
                >
                  version:0.0.3
				    
                </label> 
              </center>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
