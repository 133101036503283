import React, { useEffect, useState } from "react";
import Transaction1 from "../layout/AddChannelHeader";
import Footer from "../layout/Footer";
import "../../assets/styles/Channels.css";
import { useMediaQuery } from '@mui/material';
import authApi3 from '../services/authApi';
import Loader from "react-js-loader";
import Button from "../elements/Button";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
export default function Transactions() {
    const [selectTransaction, setSelectTransaction] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const [searchQuery, setSearchQuery] = useState("");
    const [opDialogOpen, setOpDialogOpen] = useState(false);

    // Define a function to handle changes in the search input
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleOpDialogOpen = () => {
        setOpDialogOpen(true);
    }
	
  const history1 = useHistory();
 const handlelogout = () => {
    localStorage.removeItem("custNumber");
    localStorage.clear();
    // Use history.push to redirect to the home page ("/")
   
    history1.push("/");  // Redirect to the homepage
     
  };
    const handleOpDialogClose = () => {
        setOpDialogOpen(false);
    }
    

    // Filter transactions based on the search query
    const filteredTransactions = selectTransaction.filter(transaction =>
        transaction.TRAN_ID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transaction.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transaction.COLLECTION_DATE.includes(searchQuery)
    );

    useEffect(() => {
        setIsLoading(true);
        const fetchTransactions = async () => {
            let data = {
                cust_id: localStorage.getItem("custNum")
            };
            try {
                const res = await authApi3.fetchTransactions(data);
                if (res.status === 200) {
                    setSelectTransaction(res.data?.customerPaymentHistory);
                    setIsLoading(false);
                }
            } catch (error) {
                console.log("Failed to fetch transactions", error);
                setIsLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    const Loading = () => (
        <center>
            <div className="container-sm">
                <div className="hero-content">
                    <div style={{ marginTop: "120px", position: "relative" }}>
                        <Loader
                            type="box-rectangular"
                            bgcolor={"#FFA500"}
                            color={"#FFA500"}
                            size={80}
                        />
                    </div>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 1,
                        backgroundColor: "rgba(0,0,0,0.5)"
                    }} />
                </div>
            </div>
        </center>
    );

    return (
        <>
            <Transaction1 style={{ width: "100%" }} title={"Transactions"} onlogoutBtnClick={handlelogout} onContactBtnClick={handleOpDialogOpen}/>

            {isSmallScreen ?
                <div style={{ margin: "100px", marginLeft: "10px", marginRight: "10px" }}>
                    <h5>Transaction History</h5>
                    {isLoading ? <Loading /> :
                        <div className="scrollable-container" style={{ overflowX: "auto", height: "370px" }}>
                              <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchInputChange}
                                placeholder="Search transactions..."
                                style={{ marginBottom: "10px"}}
                            />
                            <table>
                                <tbody>
                                    {filteredTransactions.map(transaction => (
                                        <tr key={transaction.TRAN_ID}>
                                            <td>
                                                <h4 style={{ color: "#4AF9A1" }}>
                                                    {transaction.SUCCESS}
                                                </h4>
                                            </td>
                                            <td>
                                                <div style={{ textAlign: "right", padding: "40px" }}>
                                                    ₹{Math.ceil(transaction.collected_amount)}
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ textAlign: "left", color: "#FFBF00" }}>
                                                    <text style={{ color: "white" }}>Train id: </text>{transaction.TRAN_ID}
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ textAlign: "left" }}>
                                                    {transaction.COLLECTION_DATE.split('T')[0]}
                                                </div>
                                                <div style={{ textAlign: "left", borderLeft: "1px solid white", paddingLeft: "10px" }}>
                                                    {transaction.COLLECTION_DATE.split('T')[1].split('.')[0]}
                                                </div>
                                                <div style={{ borderLeft: "1px solid white", paddingLeft: "10px" }}>
                                                    {transaction.name}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                :
                <div style={{ margin: "50px" }}>
                    <h5>Transaction History</h5>
                    {isLoading ? <Loading /> :
                        <div className="scrollable-container" style={{ overflowX: "auto", height: "530px" }}>
                              <input
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchInputChange}
                                placeholder="Search transactions..."
                                style={{ marginBottom: "10px" }}
                            />
                            <table>
                                <tbody>
                                    {filteredTransactions.map(transaction => (
                                        <tr key={transaction.TRAN_ID}>
                                            <td>
                                                <h4 style={{ color: "#4AF9A1" }}>
                                                    {transaction.SUCCESS}
                                                </h4>
                                            </td>
                                            <td>
                                                <div style={{ textAlign: "right", padding: "40px" }}>
                                                    ₹{Math.ceil(transaction.collected_amount)}
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ textAlign: "left", color: "#FFBF00" }}>
                                                    <text style={{ color: "white" }}>Tran id: </text>{transaction.TRAN_ID}
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ textAlign: "left" }}>
                                                    {transaction.COLLECTION_DATE.split('T')[0]}
                                                </div>
                                                <div style={{ textAlign: "left", borderLeft: "1px solid white", paddingLeft: "10px" }}>
                                                    {transaction.COLLECTION_DATE.split('T')[1].split('.')[0]}
                                                </div>
                                                <div style={{ borderLeft: "1px solid white", paddingLeft: "10px" }}>
                                                    {transaction.name}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
             <Dialog
        open={opDialogOpen}
      >
        <DialogTitle>
          <Typography variant="h6" color={"black"} align="center">
            Contact Info
          </Typography>
        </DialogTitle>
        <DialogContent align="left">
        <DialogContentText>
          Network Name:&nbsp;{localStorage.getItem("operatorName")}
          </DialogContentText>
          <DialogContentText>
          Phone&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
            {localStorage.getItem("op_phone") ? (
              <a href={`tel:${localStorage.getItem("op_phone")}`} style={{color:"#9CA9B3"}}>{localStorage.getItem("op_phone")}</a>
            ) : (
              ''
            )}
          </DialogContentText>
          <DialogContentText>
              Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
              {localStorage.getItem("op_email") ? (
                <a href={`mailto:${localStorage.getItem("op_email")}`} style={{color:"#9CA9B3"}}>{localStorage.getItem("op_email")}</a>
              ) : (
                ''
              )}
          </DialogContentText>
          <DialogContentText>
            <div style={{ display: 'flex', alignItems: 'right', width: '100%' }}>
              <span style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Address&nbsp;:</span>
              <div style={{ flexGrow: 1,color:"#9CA9B3"}}>
                
                  {localStorage.getItem("op_address")}
               
              </div>
            </div>
            
          </DialogContentText>
          <DialogActions>
            <Button 
                style={{width: '100%', backgroundColor: '#1CB68B', color: 'white', border: '0px solid green', borderRadius: '15px'}} 
                onClick={() => { handleOpDialogClose() }} onClose={() => { handleOpDialogClose() }}
            >Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
            <Footer />
        </>
    )
}
