import React, {useState, useEffect} from 'react'
import AddChannelHeader from '../components/layout/AddChannelHeader';
import Footer from '../components/layout/Footer';
import Loader from "react-js-loader";
import authApi3 from '../components/services/authApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import swal from "sweetalert";
import Button from "../components/elements/Button";
import { useMediaQuery } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";
import "../assets/styles/Channels.css";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
function AddComplaints() {
  const [activeTab,setactiveTab]=useState("add");
  const complaintVlues = {complaintType: '', complaintDesc: ''};
  const [comValue, setComValue] = useState(complaintVlues);
  const [showCompaints, setShowCompaints] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [opDialogOpen, setOpDialogOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleOpDialogOpen = () => {
    setOpDialogOpen(true);
  }

  const handleOpDialogClose = () => {
    setOpDialogOpen(false);
  }

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleTabClick=(tabName)=>{
      setactiveTab(tabName);
      setComplaintType("");
  };

  const history1 = useHistory();
 const handlelogout = () => {
    localStorage.removeItem("custNumber");
    localStorage.clear();
    // Use history.push to redirect to the home page ("/")
   
    history1.push("/");  // Redirect to the homepage
     
  };
  const handleValueChange = (e) => {
      const {name, value} = e.target;
      setComValue({...comValue, [name]: value});
      if (name === 'complaintType') {
        setComplaintType(value);
        setSelectedValue("");
      } 
   }

  useEffect(() => {
    fetchCompaints();
  },[])

  const fetchCompaints = async () => {
      setIsLoading(true);
      try {
          const data = {
              cust_num: localStorage.getItem("custNum")
          };
          const response = await authApi3.fetchComplaints(data);
          console.log("API Response:", response);
          if (response.status === 200) {
              setIsLoading(false);
              setShowCompaints(response.data?.complaintList);
          }
      } catch (error) {
          setIsLoading(false);
          console.log("Failed to fetch the transaction", error);
      }
  };

  const AddCompaints=async()=>{
      setIsLoading(true);
      try{
          if(selectedValue===""||comValue.complaintDesc===""||comValue.complaintType===""){
            swal("Empty Fields!!","","error");
            setIsLoading(false);
            return;
          }
          let data = {
            records: [
              {
                complaint_summary: {
                  customer_number: localStorage.getItem("custNum"),
                  customer_id: localStorage.getItem("custID"),
                  complaint_title_id: "102",
                  complaint_title: selectedValue,
                  complaint_description: comValue.complaintDesc,
                  complaint_type: comValue.complaintType,
                  complaint_status: "Pending",
                  logged_by: localStorage.getItem("operatorId"),
                  owner_id: localStorage.getItem("operatorId"),
                  active: "Yd",
                  dmlType: "Iv",
                  recordType: "No",
                  created_by: "QR_Payment",
                  created_by_id: localStorage.getItem("operatorId"),
                  comments: "New Complaintss"
                }
              }
            ]
          }
          const response=await authApi3.addComplaints(data);
          console.log("API response:",response);
          if(response.status==200){
              setIsLoading(false);
              setComplaintType("");
              setComValue(complaintVlues)
              if(response.data?.p_out_mssg_flg === 'E'){
                swal("Error!", response.data.p_out_mssg, "error");
              }else if(response.data?.p_out_mssg_flg === 'S'){
                fetchCompaints();
                swal("Added Successfully","","success");
              }
           }
      }catch(error){
          setIsLoading(false);
          console.log("Failed to fetch the transaction",error);
          swal("Error!", "Unexpected problem occurred, Please try again", "error");
      }
  };

  const Loading=() => {
    return(
        <center>
          <div className="container-sm">
            <div className="hero-content">
              <div style={{ marginTop: "120px", position: "relative" }}>
                <Loader
                  type="box-rectangular"
                  bgColor={"#FFA500"}
                  color={"#FFA500"}
                  size={80}
                />
              </div>
              <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                backgroundColor: "rgba(0,0,0,0.5)"
              }}/>
            </div>
          </div>
      </center>
    );
  };

  return (
    <>    
    <AddChannelHeader title={"Complaints"}  onlogoutBtnClick={handlelogout} onContactBtnClick={handleOpDialogOpen}/>
    <section className="hero section center-content">
        <div className="bodyMainConatiner">
          <div className="tabsButtonContainer">
              <button className={activeTab==="add"?"active":"tabButton"} onClick={() => handleTabClick("add")}>Add Complaints</button>
              <button className={activeTab==="view"?"active":"tabButton"} onClick={() => handleTabClick("view")}>View Complaints</button>
          </div>
          <div className={isSmallScreen?"tab-content-mobile":"tab-content"}>
              {activeTab === "add" && 
                <>
                  <hr />
                  {isLoading? <Loading /> :
                    <div className='complaintsMainConatiner'>
                      <div style={{width: isSmallScreen? "100%" :"90%", minHeight: "400px", display: "flex", flexDirection: "column", alignItems: "center", gap: "20px"}}>
                          <select name="complaintType" className='complaintsInputEle' onChange={(e)=> handleValueChange(e)}>
                              <option value="" selected>--Select Complaint Type---</option>
                              <option value="Payment">Payment</option>
                              <option value="Services">Services</option>
                          </select>
                          {complaintType && complaintType==='Payment'?
                            <div style={{width: "90%",border: "1px solid white", borderRadius: "15px", padding: "10px",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" ,gap: "10px"}}>
                              <div style={{display: "flex", alignItems: "left"}}>
                                <input type='radio' value="I'm having issue with my payments" checked={selectedValue==="I'm having issue with my payments"} onChange={handleRadioChange}/>
                                <label style={{marginLeft: "5px", textAlign: "left"}}>I'm having issue with my payments</label>
                              </div>
                              <div style={{display: "flex", alignItems: "left"}}>
                                <input type='radio' value="I'm not able to watch specific Channel" checked={selectedValue==="I'm not able to watch specific Channel"} onChange={handleRadioChange}/>
                                <label style={{marginLeft: "5px", textAlign: "left"}}>I'm not able to watch specific channel</label>
                              </div>
                              <div style={{display: "flex", alignItems: "left"}}>
                                <input type='radio' value="IVR problem" checked={selectedValue==="IVR problem"} onChange={handleRadioChange}/>
                                <label style={{marginLeft: "5px", textAlign: "left"}}>IVR problem</label>
                              </div>
                            </div>
                          :""}
                          {complaintType && complaintType==='Services'?
                            <div style={{width: "90%",border: "1px solid white", borderRadius: "15px", padding: "10px",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" ,gap: "10px"}}>
                              <div style={{display: "flex", alignItems: "left"}}>
                                <input type='radio' value="My STB is not receiving any signal" checked={selectedValue==="My STB is not receiving any signal"} onChange={handleRadioChange}/>
                                <label style={{marginLeft: "5px", textAlign: "left"}}>My STB is not receiving any signal</label>
                              </div>
                              <div style={{display: "flex", alignItems: "left"}}>
                                <input type='radio' value="Remote control issue" checked={selectedValue==="Remote control issue"} onChange={handleRadioChange}/>
                                <label style={{marginLeft: "5px", textAlign: "left"}}>Remote control issue</label>
                              </div>
                              <div style={{display: "flex", alignItems: "left"}}>
                                <input type='radio' value="Some channel are not available" checked={selectedValue==="Some channel are not available"} onChange={handleRadioChange}/>
                                <label style={{marginLeft: "5px", textAlign: "left"}}>Some channel are not available</label>
                              </div>
                            </div>
                          :""}
                          <textarea name="complaintDesc" className='complaintsTextareaInput' rows="5" placeholder="Please describe your issue here...✍️" onChange={(e)=> handleValueChange(e)} />
                          <button className='addComplaintsButton' onClick={()=> AddCompaints()}>📧 Submit Complaint</button>
                        </div>
                    </div>

                  } 
                </>
              }
              {activeTab === "view" && 
              <>
                <hr />
                {isLoading? <Loading /> :
                    <div className='complaintsMainConatiner'>
                      <div style={{width: isSmallScreen? "100%" :"90%", minHeight: "300px", maxHeight: "400px", display: "flex", flexDirection: "column", alignItems: "center", gap: "15px", overflowY: "scroll", overflowX: "hidden", scrollbarWidth: "none"}}>
                        {
                          showCompaints.length > 0? (
                              showCompaints.map((complaint, index) => (
                              <div key={index} style={{width: isSmallScreen? "100%" :"90%",border: "1px solid white", borderRadius: "15px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <table style={{marginBottom: "0px"}}>
                                  <tbody>
                                    <tr>
                                      <td colSpan="3">
                                        Complaint ID : {complaint.comp_id}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{width: "50%"}}>
                                        <center>
                                          Type : {complaint.cmp_Type}
                                        </center>
                                      </td>
                                      <td style={{width: "50%"}}>
                                        <div style={{textAlign: "left"}}>
                                          { (() => {
                                              const parsedDate = moment(complaint.comp_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMMM-YYYY');
                                              return parsedDate !== 'Invalid date' ? parsedDate : "📅 Date Not Mentioned!!";
                                            })()
                                          }
                                        </div>
                                      </td>
                                      {/* <td style={{width: "10%"}}>
                                        <div style={{textAlign: "left"}}>
                                          <FontAwesomeIcon style={{marginLeft: '10px', width: '15px', height: '15px'}} icon={faCircleInfo} color="white" onClick={() => console.log("Info Button clicked")}/>
                                        </div>
                                      </td> */}
                                    </tr>
                                    <tr>
                                      <td colSpan="3" className="channel-tbl-rate-clm">
                                        {complaint.comp_status}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ))
                          ):(
                            <center>
                              <p>No complaints to show!</p>
                            </center>
                          )
                        }
                      </div>
                    </div>
                }
              </>
              } 
          </div>
        </div>
        <hr />
        <Dialog
        open={opDialogOpen}
      >
        <DialogTitle>
          <Typography variant="h6" color={"black"} align="center">
            Contact Info
          </Typography>
        </DialogTitle>
        <DialogContent align="left">
        <DialogContentText>
          Network Name:&nbsp;{localStorage.getItem("operatorName")}
          </DialogContentText>
          <DialogContentText>
          Phone&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
            {localStorage.getItem("op_phone") ? (
              <a href={`tel:${localStorage.getItem("op_phone")}`} style={{color:"#9CA9B3"}}>{localStorage.getItem("op_phone")}</a>
            ) : (
              ''
            )}
          </DialogContentText>
          <DialogContentText>
              Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
              {localStorage.getItem("op_email") ? (
                <a href={`mailto:${localStorage.getItem("op_email")}`} style={{color:"#9CA9B3"}}>{localStorage.getItem("op_email")}</a>
              ) : (
                ''
              )}
          </DialogContentText>
          <DialogContentText>
            <div style={{ display: 'flex', alignItems: 'right', width: '100%' }}>
              <span style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>Address&nbsp;:</span>
              <div style={{ flexGrow: 1,color:"#9CA9B3"}}>
                
                  {localStorage.getItem("op_address")}
               
              </div>
            </div>
            
          </DialogContentText>
          <DialogActions>
            <Button 
                style={{width: '100%', backgroundColor: '#1CB68B', color: 'white', border: '0px solid green', borderRadius: '15px'}} 
                onClick={() => { handleOpDialogClose() }} onClose={() => { handleOpDialogClose() }}
            >Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </section>
    <Footer />
    </>
  )
}

export default AddComplaints